import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { CheckFieldSpec } from '@aurora/shared-client/components/form/CheckField/CheckField';
import {
  FormCheckInputType,
  FormFieldVariant,
  FormInputFieldInputType
} from '@aurora/shared-client/components/form/enums';
import type { IconRadioSpec } from '@aurora/shared-client/components/form/IconRadioField/IconRadioField';
import type { InputFieldSpec } from '@aurora/shared-client/components/form/InputField/InputField';
import type {
  MultiCheckFieldOption,
  MultiCheckFieldSpec
} from '@aurora/shared-client/components/form/MultiCheckField/MultiCheckField';
import type { PillRadioSpec } from '@aurora/shared-client/components/form/PillRadioField/PillRadioField';
import type { PlaceholderFormFieldSpec } from '@aurora/shared-client/components/form/PlaceholderFormField/PlaceholderFormField';
import type { RangeFieldSpec } from '@aurora/shared-client/components/form/RangeField/RangeField';
import type { SelectFieldSpec } from '@aurora/shared-client/components/form/SelectField/SelectField';
import Icons from '@aurora/shared-client/icons';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type React from 'react';
import { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ConfigurationSpec, InstanceScopedWidgetProps } from '../../../common/Widget/types';
import useTranslation from '../../../useTranslation';
import type {
  CardLayoutOptions,
  CardLayoutProps,
  ConfigurationFormData,
  DetailedListAndCardSharedLayoutOptions,
  DetailedListLayoutOptions,
  DetailedListLayoutProps,
  FeaturedContentWidgetConfigurableProps,
  FeaturedContentWidgetProps,
  LayoutOptions,
  LayoutProps,
  ListLayoutOptions,
  ListLayoutProps
} from '../FeaturedContentWidget/types';
import {
  FeaturedContentWidgetLayout,
  FeaturedContentWidgetListStyle,
  LeadWithOption
} from '../FeaturedContentWidget/types';
import formSchema from './FeaturedContentWidgetEditor.form.json';

/**
 * User defined type-guard for working with props specific to the List layout
 *
 * @param layoutProps the layout props
 */
export function isLayoutPropsListLayout(layoutProps: LayoutProps): layoutProps is ListLayoutProps {
  return layoutProps?.layout === FeaturedContentWidgetLayout.LIST;
}

/**
 * User defined type-guard for working with props specific to the DetailedList layout
 *
 * @param layoutProps the layout props
 */
export function isLayoutPropsDetailedListLayout(
  layoutProps: LayoutProps
): layoutProps is DetailedListLayoutProps {
  return layoutProps?.layout === FeaturedContentWidgetLayout.DETAILED_LIST;
}

/**
 * User defined type-guard for working with props specific to the Card layout
 *
 * @param layoutProps the layout props
 */
export function isLayoutPropsCardLayout(layoutProps: LayoutProps): layoutProps is CardLayoutProps {
  return layoutProps?.layout === FeaturedContentWidgetLayout.CARD;
}

export const defaultListLayoutProps: ListLayoutProps = {
  layout: FeaturedContentWidgetLayout.LIST,
  layoutOptions: {
    useAuthorLink: true,
    useTimestamp: true
  },
  listStyle: FeaturedContentWidgetListStyle.DIVIDE,
  leadWithOption: LeadWithOption.AVATAR
};

const defaultDetailedListAndCardSharedLayoutOptions: DetailedListAndCardSharedLayoutOptions = {
  useAuthorLink: true,
  useAuthorRank: false,
  useBody: true,
  usePreviewMedia: true,
  useKudosCount: true,
  useRepliesCount: true,
  useTags: true,
  useTimestamp: true,
  useBoardLink: true,
  useViewCount: true,
  useSolvedBadge: true,
  useTimeToRead: true
};

export const defaultDetailedListLayoutProps: DetailedListLayoutProps = {
  layout: FeaturedContentWidgetLayout.DETAILED_LIST,
  layoutOptions: {
    ...defaultDetailedListAndCardSharedLayoutOptions,
    useUnreadMessages: true
  },
  listStyle: FeaturedContentWidgetListStyle.DIVIDE,
  leadWithOption: LeadWithOption.AVATAR
};

export const defaultCardLayoutProps: CardLayoutProps = {
  layout: FeaturedContentWidgetLayout.CARD,
  layoutOptions: {
    ...defaultDetailedListAndCardSharedLayoutOptions,
    useCenteredCardContent: false
  }
};

/**
 * Merges the current options for the previously selected layout with the newly selected layout's default options,
 * provided that they share any options
 *
 * @param existingData the existing layout options
 * @param selectedLayoutDefaultProps the default props for the selected layout
 */
export function mergeExisting<LayoutOptionT extends LayoutProps>(
  existingData: {
    listStyle: FeaturedContentWidgetListStyle;
    leadWithOption: LeadWithOption;
    layoutOptions: LayoutOptions;
  },
  selectedLayoutDefaultProps: LayoutOptionT
): LayoutOptionT {
  const targetProps: LayoutOptionT = { ...selectedLayoutDefaultProps };
  const { listStyle, leadWithOption, layoutOptions } = existingData;
  if (!isLayoutPropsCardLayout(targetProps)) {
    // preserve list style selection if possible
    if (typeof listStyle === 'string') {
      targetProps.listStyle = listStyle;
    }
    // preserve lead with option selection if possible
    if (typeof leadWithOption === 'string') {
      targetProps.leadWithOption = leadWithOption;
    }
  }
  // preserve layout option selections if possible
  Object.entries(layoutOptions).forEach(([key, value]) => {
    if (key in targetProps.layoutOptions) {
      targetProps.layoutOptions[key] = value;
    }
  });

  return targetProps;
}

/**
 * Maps the List layout option values to the MultiCheckFieldSpec
 */
export function getListLayoutMultiCheckFieldOptions(): Array<
  MultiCheckFieldOption<keyof ListLayoutOptions, 'listLayoutOptions', ConfigurationFormData>
> {
  return Object.keys(defaultListLayoutProps.layoutOptions).map((key: keyof ListLayoutOptions) => {
    return { name: key };
  });
}

/**
 * Maps the DetailedList layout option values to the MultiCheckFieldSpec
 */
export function getDetailedListLayoutMultiCheckFieldOptions(
  useIdeas = false,
  isBoard = false,
  formatMessage = null
): Array<
  MultiCheckFieldOption<
    keyof DetailedListLayoutOptions,
    'detailedListLayoutOptions',
    ConfigurationFormData
  >
> {
  return [
    { name: 'useAuthorLink' },
    { name: 'useAuthorRank' },
    { name: 'useBody' },
    { name: 'usePreviewMedia' },
    {
      name: 'useKudosCount',
      label: useIdeas && !isBoard ? formatMessage('useKudosCount.containerNode.label') : null
    },
    { name: 'useRepliesCount' },
    { name: 'useTags' },
    { name: 'useTimestamp' },
    { name: 'useBoardLink' },
    { name: 'useViewCount' },
    { name: 'useSolvedBadge' },
    { name: 'useTimeToRead' },
    { name: 'useUnreadMessages' }
  ];
}

/**
 * Maps the Card layout option values to the MultiCheckFieldSpec
 */
export function getCardLayoutMultiCheckFieldOptions(
  useIdeas = false,
  isBoard = false,
  formatMessage = null
): Array<
  MultiCheckFieldOption<keyof CardLayoutOptions, 'cardLayoutOptions', ConfigurationFormData>
> {
  return [
    { name: 'useAuthorLink' },
    { name: 'useBody' },
    { name: 'usePreviewMedia' },
    {
      name: 'useKudosCount',
      label: useIdeas && !isBoard ? formatMessage('useKudosCount.containerNode.label') : null
    },
    { name: 'useRepliesCount' },
    { name: 'useTags' },
    { name: 'useTimestamp' },
    { name: 'useBoardLink' },
    { name: 'useViewCount' },
    { name: 'useSolvedBadge' },
    { name: 'useTimeToRead' },
    { name: 'useCenteredCardContent' },
    { name: 'useAuthorRank' }
  ];
}

/**
 * Takes the current form data transforms it for use by the widget props
 *
 * @param data the form data
 * @param layout the current FeaturedContentWidget layout from props
 * @param instanceId the FeaturedContentWidget instance id
 */
export function transformToWidgetProps(
  data: ConfigurationFormData,
  layout: FeaturedContentWidgetLayout,
  instanceId: string
): FeaturedContentWidgetProps {
  const {
    layout: selectedLayout,
    listLayoutOptions,
    detailedListLayoutOptions,
    cardLayoutOptions,
    listStyle,
    leadWithOption,
    moreOptions
  } = data;
  const commonProps: Omit<FeaturedContentWidgetProps, 'layoutProps'> = {
    showPager: moreOptions.showPager,
    lazyLoad: moreOptions.lazyLoad,
    titleSrOnly: data.titleSrOnly,
    pageSize: data.pageSize,
    instanceId
  };

  const isNewLayoutSelected: boolean = selectedLayout !== layout;

  if (selectedLayout === FeaturedContentWidgetLayout.LIST) {
    if (!isNewLayoutSelected) {
      const newLayoutProps: ListLayoutProps = {
        layout: selectedLayout,
        layoutOptions: listLayoutOptions,
        listStyle,
        leadWithOption
      };

      return { ...commonProps, layoutProps: newLayoutProps };
    } else {
      const newLayoutProps: ListLayoutProps = {
        ...mergeExisting<ListLayoutProps>(
          {
            layoutOptions: detailedListLayoutOptions || cardLayoutOptions,
            listStyle,
            leadWithOption
          },
          defaultListLayoutProps
        )
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    }
  } else if (selectedLayout === FeaturedContentWidgetLayout.DETAILED_LIST) {
    if (!isNewLayoutSelected) {
      const newLayoutProps: DetailedListLayoutProps = {
        layout: selectedLayout,
        layoutOptions: detailedListLayoutOptions,
        listStyle,
        leadWithOption
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    } else {
      const newLayoutProps: DetailedListLayoutProps = {
        ...mergeExisting<DetailedListLayoutProps>(
          {
            layoutOptions: cardLayoutOptions || listLayoutOptions,
            listStyle,
            leadWithOption
          },
          defaultDetailedListLayoutProps
        )
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    }
  } else {
    if (!isNewLayoutSelected) {
      const newLayoutProps: CardLayoutProps = {
        layout: selectedLayout,
        layoutOptions: cardLayoutOptions
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    } else {
      const newLayoutProps: CardLayoutProps = {
        ...mergeExisting<CardLayoutProps>(
          {
            layoutOptions: detailedListLayoutOptions || listLayoutOptions,
            listStyle,
            leadWithOption
          },
          defaultCardLayoutProps
        )
      };

      return { layoutProps: newLayoutProps, ...commonProps };
    }
  }
}

/**
 * Returns the configuration spec for the FeaturedContentWidget
 *
 * @param instanceId the instance id for the widget
 * @param configurableProps configurable props for the widget
 * @param titlePlaceholder the placeholder for the title field
 * @param editContentButton the edit content button
 */
export default function useFeaturedContentWidgetConfiguration(
  instanceId: string,
  configurableProps: FeaturedContentWidgetConfigurableProps,
  titlePlaceholder: string,
  editContentButton: React.ReactElement
): [boolean, ConfigurationSpec<InstanceScopedWidgetProps, ConfigurationFormData>] {
  const cx = useClassNameMapper();

  const { layoutProps, title, titleSrOnly, pageSize, showPager, lazyLoad } = configurableProps;
  const { layout } = layoutProps;

  const {
    publicConfig: { ideasEnabled }
  } = useContext(TenantContext);

  const { formatMessage } = useTranslation(EndUserComponent.FEATURED_CONTENT_WIDGET_EDITOR);

  const { contextNode } = useContext(AppContext);
  const isContextNodeBoard = contextNode.nodeType === NodeType.BOARD;

  /* Layout FieldGroup */
  const layoutField: IconRadioSpec<'layout', ConfigurationFormData> = {
    name: 'layout',
    fieldVariant: FormFieldVariant.ICON_RADIO,
    values: [
      {
        key: FeaturedContentWidgetLayout.DETAILED_LIST,
        value: FeaturedContentWidgetLayout.DETAILED_LIST,
        icon: Icons.ContentLayoutDetailedListIcon
      },
      {
        key: FeaturedContentWidgetLayout.LIST,
        value: FeaturedContentWidgetLayout.LIST,
        icon: Icons.ContentLayoutListIcon
      },
      {
        key: FeaturedContentWidgetLayout.CARD,
        value: FeaturedContentWidgetLayout.CARD,
        icon: Icons.ContentLayoutCardIcon
      }
    ],
    defaultValue: layout
  };

  /* List Style FieldGroup */
  const listStyleField: PillRadioSpec<'listStyle', ConfigurationFormData> = {
    name: 'listStyle',
    fieldVariant: FormFieldVariant.PILL_RADIO,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) =>
        selectedLayout !== FeaturedContentWidgetLayout.CARD
    },
    values: [
      {
        key: FeaturedContentWidgetListStyle.SPACE,
        value: FeaturedContentWidgetListStyle.SPACE
      },
      {
        key: FeaturedContentWidgetListStyle.DIVIDE,
        value: FeaturedContentWidgetListStyle.DIVIDE
      },
      {
        key: FeaturedContentWidgetListStyle.BORDER,
        value: FeaturedContentWidgetListStyle.BORDER
      }
    ],
    defaultValue: !isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.listStyle
      : defaultListLayoutProps.listStyle
  };

  /* Title FieldGroup */
  const titleField: InputFieldSpec<'title', ConfigurationFormData> = {
    name: 'title',
    fieldVariant: FormFieldVariant.INPUT,
    inputType: FormInputFieldInputType.TEXT,
    defaultValue: title ?? '',
    attributes: {
      placeholder: titlePlaceholder
    }
  };

  const titleSrOnlyField: CheckFieldSpec<'titleSrOnly', ConfigurationFormData> = {
    name: 'titleSrOnly',
    fieldVariant: FormFieldVariant.CHECK,
    inputType: FormCheckInputType.SWITCH,
    defaultValue: titleSrOnly
  };

  /* Edit Content FieldGroup */
  const editContentField: PlaceholderFormFieldSpec<'editContent', ConfigurationFormData> = {
    name: 'editContent',
    fieldVariant: FormFieldVariant.PLACEHOLDER_FORM_FIELD,
    Content: () => editContentButton,
    defaultValue: null,
    formGroupSpec: {
      label: false
    }
  };

  /* Page Size FieldGroup */
  const pageSizeField: RangeFieldSpec<'pageSize', ConfigurationFormData> = {
    name: 'pageSize',
    fieldVariant: FormFieldVariant.RANGE,
    showOutput: true,
    defaultValue: pageSize,
    min: 1,
    max: 20
  };

  /* Layout Options FieldGroup */
  const listLayoutListItemsField: MultiCheckFieldSpec<'listLayoutOptions', ConfigurationFormData> =
    {
      name: 'listLayoutOptions',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: getListLayoutMultiCheckFieldOptions(),
      defaultValue: isLayoutPropsListLayout(layoutProps)
        ? layoutProps.layoutOptions
        : defaultListLayoutProps.layoutOptions,
      isVisible: {
        watchFields: ['layout'],
        callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
          return selectedLayout === FeaturedContentWidgetLayout.LIST;
        }
      }
    };

  const detailedListLayoutListItemsField: MultiCheckFieldSpec<
    'detailedListLayoutOptions',
    ConfigurationFormData
  > = {
    name: 'detailedListLayoutOptions',
    fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
    inputType: FormCheckInputType.SWITCH,
    options: getDetailedListLayoutMultiCheckFieldOptions(
      ideasEnabled,
      isContextNodeBoard,
      formatMessage
    ),
    defaultValue: isLayoutPropsDetailedListLayout(layoutProps)
      ? layoutProps.layoutOptions
      : defaultDetailedListLayoutProps.layoutOptions,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout === FeaturedContentWidgetLayout.DETAILED_LIST;
      }
    }
  };

  const cardLayoutItemsField: MultiCheckFieldSpec<'cardLayoutOptions', ConfigurationFormData> = {
    name: 'cardLayoutOptions',
    fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
    inputType: FormCheckInputType.SWITCH,
    options: getCardLayoutMultiCheckFieldOptions(ideasEnabled, isContextNodeBoard, formatMessage),
    defaultValue: isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.layoutOptions
      : defaultCardLayoutProps.layoutOptions,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout === FeaturedContentWidgetLayout.CARD;
      }
    }
  };

  const leadWithOptionField: SelectFieldSpec<'leadWithOption', ConfigurationFormData> = {
    name: 'leadWithOption',
    fieldVariant: FormFieldVariant.SELECT,
    defaultValue: !isLayoutPropsCardLayout(layoutProps)
      ? layoutProps.leadWithOption
      : defaultListLayoutProps.leadWithOption,
    isVisible: {
      watchFields: ['layout'],
      callback: ({ layout: selectedLayout }: ConfigurationFormData) => {
        return selectedLayout !== FeaturedContentWidgetLayout.CARD;
      }
    },
    values: [
      {
        key: LeadWithOption.ICON,
        value: LeadWithOption.ICON
      },
      {
        key: LeadWithOption.AVATAR,
        value: LeadWithOption.AVATAR
      },
      {
        key: LeadWithOption.NONE,
        value: LeadWithOption.NONE
      }
    ]
  };

  /* More Options Field Group */
  const moreOptionsField: MultiCheckFieldSpec<'moreOptions', ConfigurationFormData> = {
    name: 'moreOptions',
    fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
    inputType: FormCheckInputType.SWITCH,
    defaultValue: {
      showPager,
      lazyLoad
    },
    options: [
      {
        name: 'showPager'
      },
      {
        name: 'lazyLoad'
      }
    ]
  };

  return [
    false,
    {
      instanceId,
      componentId: EndUserComponent.FEATURED_CONTENT_WIDGET,
      fieldSpecs: [
        layoutField,
        listStyleField,
        titleField,
        titleSrOnlyField,
        editContentField,
        pageSizeField,
        listLayoutListItemsField,
        detailedListLayoutListItemsField,
        cardLayoutItemsField,
        leadWithOptionField,
        moreOptionsField
      ],
      formSchema: { cx, schema: formSchema },
      id: 'FeaturedContentWidgetEditor',
      submitOnChange: {
        watchFields: ['title']
      },
      textOverrideSpecs: [
        {
          fieldName: 'title',
          textKey: 'title'
        }
      ],
      transformToWidgetProps: (data: ConfigurationFormData) =>
        transformToWidgetProps(data, layout, instanceId)
    }
  ];
}
