import type { ApolloError } from '@apollo/client';
import type { QueryResult } from '@apollo/client/react/types/types';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canUpdateFeaturedWidget } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import Icons from '@aurora/shared-client/icons';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  NodePoliciesQuery,
  QuiltFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  FeaturedContentWidgetQuery,
  FeaturedContentWidgetQueryVariables
} from '../../../../types/graphql-types';
import EditableWidget from '../../../common/Widget/EditableWidget';
import type {
  InstanceScopedWidgetFC,
  InstanceScopedWidgetProps
} from '../../../common/Widget/types';
import type { EditContextInterface } from '../../../context/EditContext/EditContext';
import EditContext from '../../../context/EditContext/EditContext';
import useTranslation from '../../../useTranslation';
import FeaturedContentMessageList from '../FeaturedContentMessageList/FeaturedContentMessageList';
import {
  defaultDetailedListLayoutProps,
  isLayoutPropsCardLayout,
  isLayoutPropsListLayout
} from '../FeaturedContentWidgetEditor/useFeaturedContentWidgetConfiguration';
import localStyles from './FeaturedContentWidget.module.pcss';
import type { FeaturedContentWidgetProps, LayoutProps } from './types';
import { LeadWithOption } from './types';

const log = getLog(module);

const AddFeaturedContentModal = dynamic(
  () => import('../AddFeaturedContentModal/AddFeaturedContentModal')
);

/**
 * Determines whether the FeaturedContentWidget is visible based off of the current page, node policies, associated
 * messages, and feature config
 *
 * @param isPageEditorPage whether the current page is the PageEditorPage
 * @param nodePoliciesQuery the result of the nodePoliciesQuery
 * @param hasMessages whether the widget contains messages
 */
function isFeaturedContentWidgetVisible(
  isPageEditorPage: boolean,
  nodePoliciesQuery: NodePoliciesQuery,
  hasMessages: boolean
): boolean {
  if (nodePoliciesQuery) {
    if (isPageEditorPage || hasMessages) {
      return true;
    } else {
      const { coreNode } = nodePoliciesQuery;
      return canUpdateFeaturedWidget(coreNode);
    }
  }

  return false;
}

/**
 * Returns the query variables for the FeaturedContentWidget query, optionally including message properties based off
 * of selected layout props
 *
 * @param instanceId the widget instance id
 * @param first the page size
 * @param quiltId the id of the quilt
 * @param coreNodeId the id of the core node
 * @param layoutProps the FeaturedContentWidget Layout props
 * @param isAnonymous whether the user is anonymous
 */
export function getFeaturedContentWidgetQueryVariables(
  instanceId: string,
  first: number,
  quiltId: string,
  coreNodeId: string,
  layoutProps: LayoutProps,
  isAnonymous: boolean
): FeaturedContentWidgetQueryVariables {
  // common variables used by all FeaturedContentWidget Layout types
  const commonVariables: FeaturedContentWidgetQueryVariables = {
    instanceId,
    first,
    quiltId,
    coreNodeId
  };

  const useAuthorAvatar: boolean =
    !isLayoutPropsCardLayout(layoutProps) && layoutProps.leadWithOption === LeadWithOption.AVATAR;

  if (isLayoutPropsListLayout(layoutProps)) {
    return {
      ...commonVariables,
      useAvatar: useAuthorAvatar
    };
  } else {
    const {
      useAuthorLink,
      useAuthorRank,
      useBody,
      useTags,
      useKudosCount,
      useRepliesCount,
      useSolvedBadge,
      useTimeToRead,
      usePreviewMedia
    } = layoutProps.layoutOptions;

    return {
      ...commonVariables,
      useAvatar: useAuthorAvatar,
      useAuthorLogin: useAuthorLink,
      useAuthorRank: useAuthorRank,
      useBody,
      useTextBody: useBody,
      useTags,
      useMedia: usePreviewMedia,
      useKudosCount,
      useRepliesCount,
      useSolvedBadge,
      useTimeToRead,
      useUnreadCount: !isAnonymous
    };
  }
}

const featuredContentWidgetDefaultProps: Partial<FeaturedContentWidgetProps> = {
  titleSrOnly: false,
  pageSize: 10,
  showPager: true,
  layoutProps: defaultDetailedListLayoutProps
};

export function getFinalProps(props: FeaturedContentWidgetProps): FeaturedContentWidgetProps {
  return merge(featuredContentWidgetDefaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}
/**
 * Renders the FeaturedContentWidget, which allows users to "feature" forum posts, blog posts, and kb messages from
 * anywhere in the community
 *
 * @author Jonathan Bridges
 */
const FeaturedContentWidget: InstanceScopedWidgetFC<FeaturedContentWidgetProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps = getFinalProps(rest);
  const {
    instanceId, // do not add a default value here, instanceId should be assigned via page editor or a page quilt
    className,
    layoutProps,
    titleSrOnly,
    pageSize,
    showPager
  } = finalProps;
  const cx = useClassNameMapper(localStyles);
  const {
    publicConfig: { auroraFeaturedWidgetsEnabled }
  } = useContext(TenantContext);
  const quilt: QuiltFragment = useContext(QuiltContext);
  const pageEditorContextInterface: EditContextInterface = useContext(EditContext);
  const {
    contextNode: { id: nodeId }
  } = useContext(AppContext);

  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.FEATURED_CONTENT_WIDGET
  );

  const { router, loading: routesLoading } = useEndUserRoutes();
  const isPageEditorPage: boolean = router.getCurrentPageName() === EndUserPages.PageEditorPage;

  const quiltId: string = isPageEditorPage ? pageEditorContextInterface?.quilt?.id : quilt?.id;

  const { isAnonymous } = useRegistrationStatus();

  const contentQueryVariables: FeaturedContentWidgetQueryVariables =
    getFeaturedContentWidgetQueryVariables(
      instanceId,
      pageSize,
      quiltId,
      nodeId,
      layoutProps,
      isAnonymous
    );

  const [showAddContentModal, setShowAddContentModal] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(-1);
  const [contentQueryError, setContentQueryError] = useState<ApolloError>(null);

  /**
   * Callback function used to determine if there are any existing messages for the widget along with the query loading
   * and error state
   *
   * @param queryResult the result of the FeaturedContentWidget or ProvisionedFeaturedContentMessages query
   */
  const onContentQueryUpdate = useCallback(
    (queryResult: QueryResult<FeaturedContentWidgetQuery, FeaturedContentWidgetQueryVariables>) => {
      const { data, error } = queryResult;
      setContentQueryError(error);
      if (data) {
        const {
          featuredContentWidget: {
            messages: { totalCount }
          }
        } = data;
        setMessageCount(totalCount);
      }
    },
    []
  );

  const {
    data: nodePoliciesQueryData,
    loading: nodePoliciesQueryLoading,
    error: nodePoliciesQueryError
  } = useNodePolicies(
    module,
    {
      canManageFeaturedWidget: true,
      canUpdateFeaturedWidget: true
    },
    !auroraFeaturedWidgetsEnabled
  );

  useEffect(() => {
    if (!auroraFeaturedWidgetsEnabled) {
      isVisible(false);
    } else if (
      !textLoading &&
      !nodePoliciesQueryLoading &&
      !isPageEditorPage &&
      messageCount !== -1
    ) {
      isVisible(
        isFeaturedContentWidgetVisible(isPageEditorPage, nodePoliciesQueryData, messageCount > 0)
      );
    }
  }, [
    auroraFeaturedWidgetsEnabled,
    isPageEditorPage,
    isVisible,
    messageCount,
    nodePoliciesQueryData,
    nodePoliciesQueryLoading,
    textLoading
  ]);

  /**
   * Toggles the visibility of the AddFeaturedContentModal
   */
  function toggleModal(): void {
    setShowAddContentModal(previousState => !previousState);
  }

  const title: string = formatMessage('title');

  if (nodePoliciesQueryError) {
    log.error('error checking policies for FeaturedContentWidget', nodePoliciesQueryError.message);
  }

  if (contentQueryError) {
    log.error(
      `error retrieving messages for FeaturedContentWidget with instance id ${instanceId}`,
      contentQueryError.message
    );
  }

  if (
    !auroraFeaturedWidgetsEnabled ||
    (!isPageEditorPage &&
      typeof messageCount === 'number' &&
      messageCount === 0 &&
      !canUpdateFeaturedWidget(nodePoliciesQueryData?.coreNode)) ||
    textLoading ||
    routesLoading ||
    nodePoliciesQueryLoading ||
    nodePoliciesQueryError ||
    contentQueryError
  ) {
    return null;
  }

  /**
   * Renders the edit button which allows users to update the messages from the enduser application
   */
  function renderEditButton(): React.ReactElement {
    return (
      <Button
        className={cx('lia-edit-btn')}
        testId="FeaturedContentWidget.Edit.Button"
        variant={ButtonVariant.NO_VARIANT}
        onClick={() => setShowAddContentModal(previousState => !previousState)}
        aria-label={formatMessage('edit')}
      >
        <Icon icon={Icons.EditIcon} size={IconSize.PX_16} color={IconColor.GRAY_900} />
      </Button>
    );
  }

  return (
    <EditableWidget<InstanceScopedWidgetProps> props={finalProps}>
      <AddFeaturedContentModal
        show={showAddContentModal}
        onHide={() => setShowAddContentModal(false)}
        contentQueryVariables={contentQueryVariables}
      />
      <div className={cx('position-relative')}>
        {!isPageEditorPage &&
          typeof messageCount === 'number' &&
          messageCount > 0 &&
          canUpdateFeaturedWidget(nodePoliciesQueryData.coreNode) &&
          renderEditButton()}
        <FeaturedContentMessageList
          contentQueryVariables={contentQueryVariables}
          className={className}
          toggleModal={toggleModal}
          onContentQueryUpdate={onContentQueryUpdate}
          layoutProps={layoutProps}
          header={{ title, titleSrOnly }}
          showPager={showPager}
        />
      </div>
    </EditableWidget>
  );
};
export default FeaturedContentWidget;
