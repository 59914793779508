import type { InstanceScopedWidgetProps } from '../../../common/Widget/types';

export enum FeaturedContentWidgetLayout {
  DETAILED_LIST = 'detailedList',
  LIST = 'list',
  CARD = 'card'
}

export enum FeaturedContentWidgetListStyle {
  SPACE = 'space',
  DIVIDE = 'divide',
  BORDER = 'border'
}

export enum LeadWithOption {
  ICON = 'icon',
  AVATAR = 'avatar',
  NONE = 'none'
}

/**
 * Message element options available for the List layout
 */
export interface ListLayoutOptions {
  /**
   * Whether to display the message author link
   */
  useAuthorLink: boolean;
  /**
   * Whether to display the message timestamp
   */
  useTimestamp: boolean;
}

/**
 * Message element options available for the DetailedList and Card layouts
 */
export interface DetailedListAndCardSharedLayoutOptions extends ListLayoutOptions {
  /**
   * Whether to display the message author's rank
   */
  useAuthorRank: boolean;
  /**
   * Whether to display the message body preview / teaser
   */
  useBody: boolean;
  /**
   * Whether to display a preview for the message
   */
  usePreviewMedia: boolean;
  /**
   * Whether to display the message kudos count
   */
  useKudosCount: boolean;
  /**
   * Whether to display the message board link
   */
  useBoardLink: boolean;
  /**
   * Whether to display the number of message replies
   */
  useRepliesCount: boolean;
  /**
   * Whether to display message tags
   */
  useTags: boolean;
  /**
   * Whether to display the view count metric for a message
   */
  useViewCount: boolean;
  /**
   * Whether to display the time to read (blog posts only)
   */
  useTimeToRead: boolean;
  /**
   * Whether to display the solved badge (forum discussions only)
   */
  useSolvedBadge: boolean;
}

/**
 * Message element options available for the DetailedList layout
 */
export interface DetailedListLayoutOptions extends DetailedListAndCardSharedLayoutOptions {
  /**
   * Whether to show the message's unread messages count
   */
  useUnreadMessages: boolean;
}

/**
 * Message element options available for the Card layout
 */
export interface CardLayoutOptions extends DetailedListAndCardSharedLayoutOptions {
  /**
   * Whether to center card content when in mobile (vertical) orientation
   */
  useCenteredCardContent: boolean;
}

/**
 * The possible options to display in a layout item
 */
export type LayoutOptions = ListLayoutOptions | DetailedListLayoutOptions | CardLayoutOptions;

/**
 * Properties shared by both List layouts
 */
interface CommonListLayoutProps {
  /**
   * List styling
   */
  listStyle: FeaturedContentWidgetListStyle;
  /**
   * An element that appears on the left-hand side of the message
   */
  leadWithOption: LeadWithOption;
}

/**
 * Options for the "List" layout which can be selected by the user in Page Builder
 */
export interface ListLayoutProps extends CommonListLayoutProps {
  /**
   * The layout type
   */
  layout: FeaturedContentWidgetLayout.LIST;
  /**
   * Layout options for the message unique to the List layout
   */
  layoutOptions: ListLayoutOptions;
}

/**
 * Options for the "DetailedList" layout which can be selected by the user in Page Builder
 */
export interface DetailedListLayoutProps extends CommonListLayoutProps {
  /**
   * The layout type
   */
  layout: FeaturedContentWidgetLayout.DETAILED_LIST;
  /**
   * Layout options for the message unique to the DetailedList layout
   */
  layoutOptions: DetailedListLayoutOptions;
}

/**
 * Options for the "Card" layout which can be selected by the user in Page Builder
 */
export interface CardLayoutProps {
  /**
   * The layout type
   */
  layout: FeaturedContentWidgetLayout.CARD;
  /**
   * Layout options for the message unique to the Card layout
   */
  layoutOptions: CardLayoutOptions;
}

/**
 * Props unique to one of the layout selections made in Page Builder
 */
export type LayoutProps = ListLayoutProps | DetailedListLayoutProps | CardLayoutProps;

/**
 * The FeaturedContentWidget props interface
 */
export interface FeaturedContentWidgetProps extends Omit<InstanceScopedWidgetProps, 'hideIfEmpty'> {
  /**
   * Layout selection props
   */
  layoutProps: LayoutProps;
  /**
   * Whether the title is viewable by screen readers only
   */
  titleSrOnly: boolean;
  /**
   * The number of results to show before pagination
   */
  pageSize: number;
  /**
   * Whether to display the pager
   */
  showPager: boolean;
  /**
   * Whether to enable lazy loading for the widget
   */
  lazyLoad: boolean;
}

/**
 * An extension of the props interface used to differentiate static from configurable props for the widget
 */
export interface FeaturedContentWidgetConfigurableProps
  extends Omit<FeaturedContentWidgetProps, 'instanceId' | 'className'> {
  /**
   * The user-selected title for the widget
   */
  title: string;
}

/**
 * The configuration form data interface for the FeaturedContentWidget
 */
export interface ConfigurationFormData
  extends Omit<FeaturedContentWidgetConfigurableProps, 'layoutProps' | 'showPager' | 'lazyLoad'> {
  /**
   * The layout selection
   */
  layout: FeaturedContentWidgetLayout;
  /**
   * Message element options available for the List layout
   */
  listLayoutOptions: ListLayoutOptions;
  /**
   * Message element options available for the DetailedList layout
   */
  detailedListLayoutOptions: DetailedListLayoutOptions;
  /**
   * Message element options available for the Card layout
   */
  cardLayoutOptions: CardLayoutOptions;
  /**
   * The list style for the List and DetailedList layouts
   */
  listStyle: FeaturedContentWidgetListStyle;
  /**
   * The element to display on the left of the message for the List and DetailedList layouts
   */
  leadWithOption: LeadWithOption;
  /**
   * Additional widget options
   */
  moreOptions: {
    /**
     * Whether to enable lazy loading for the widget
     */
    lazyLoad: boolean;
    /**
     * Whether to display a pager for the widget
     */
    showPager: boolean;
  };

  editContent: string;
}
