import type { QueryResult } from '@apollo/client/react/types/types';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import type { GridListTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { MessageConnection } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import getGridListColumns from '../../../../helpers/util/GridListColumnsHelper';
import { ItemType, MessageTimestamp, MessageViewVariant } from '../../../../types/enums';
import type {
  FeaturedContentWidgetQuery,
  FeaturedContentWidgetQueryVariables,
  MessageViewFragment
} from '../../../../types/graphql-types';
import PaneledItemList from '../../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../../entities/types';
import type {
  MessageViewCommonProps,
  MessageViewInlineProps
} from '../../../messages/MessageView/types';
import useTranslation from '../../../useTranslation';
import FeaturedWidgetPlaceholder from '../../FeaturedWidgetPlaceholder/FeaturedWidgetPlaceholder';
import type { LayoutProps } from '../FeaturedContentWidget/types';
import { FeaturedContentWidgetListStyle, LeadWithOption } from '../FeaturedContentWidget/types';
import useFeaturedContentWidget from '../FeaturedContentWidget/useFeaturedContentWidget';
import {
  isLayoutPropsCardLayout,
  isLayoutPropsDetailedListLayout,
  isLayoutPropsListLayout
} from '../FeaturedContentWidgetEditor/useFeaturedContentWidgetConfiguration';

interface Props {
  /**
   * Variables for the FeaturedContentWidget query
   */
  contentQueryVariables: FeaturedContentWidgetQueryVariables;
  /**
   * Callback function used to lift the query result up to the parent widget
   */
  onContentQueryUpdate: (
    queryResult: QueryResult<FeaturedContentWidgetQuery, FeaturedContentWidgetQueryVariables>
  ) => void;
  /**
   * Callback function that toggles the visibility of the AddFeaturedContentModal
   */
  toggleModal: () => void;
  /**
   * The configuration for the PaneledItemList header
   */
  header: { title: string; titleSrOnly: boolean };
  /**
   * Layout selection props
   */
  layoutProps: LayoutProps;
  /**
   * Whether to display the pager or not
   */
  showPager: boolean;
  /**
   * Class name(s) to apply to the featured content message list
   */
  className: string;
}

/**
 * Transforms the settings from the end user widget configuration for use by the MessageView components
 *
 * @param layoutProps the FeaturedContentWidget message Layout props
 */
export function getItemViewTypeAndProps(
  layoutProps: LayoutProps
): ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant> {
  const {
    layoutOptions: { useTimestamp, useAuthorLink }
  } = layoutProps;

  const useUserAvatar: boolean =
    !isLayoutPropsCardLayout(layoutProps) && layoutProps.leadWithOption === LeadWithOption.AVATAR;
  const useBoardIcon: boolean =
    !isLayoutPropsCardLayout(layoutProps) && layoutProps.leadWithOption === LeadWithOption.ICON;
  const useUserHoverCard: boolean = useUserAvatar || useAuthorLink;

  // props used by all layouts
  const commonProps: MessageViewCommonProps = {
    useAuthorLogin: useAuthorLink,
    useAuthorLoginLink: useAuthorLink,
    useUserHoverCard: useUserHoverCard,
    timeStampType: useTimestamp ? MessageTimestamp.POST_TIME : null,
    useMessageTimeLink: useTimestamp
  };

  // props used by the List and DetailedList layouts
  const inlineProps: MessageViewInlineProps = {
    useAvatar: useUserAvatar,
    useBoardIcon
  };

  if (isLayoutPropsListLayout(layoutProps)) {
    // List layout version contains minimal customization options
    const listLayoutTypeAndProps: ItemViewTypeAndProps<
      ItemType.MESSAGE,
      MessageViewVariant.INLINE
    > = {
      type: MessageViewVariant.INLINE,
      props: {
        // override MessageViewInline default props applied by MessageView.tsx
        useCompactSpacing: true,
        useNode: false,
        useNodeLink: false,
        useBody: false,
        useMedia: false,
        usePreviewMedia: false,
        useRepliesCount: false,
        useSolvedBadge: false,
        useTags: false,
        useKudosCount: false,
        ...(useUserAvatar && { avatarSize: IconSize.PX_40 }),
        ...(useBoardIcon && { boardIconSize: IconSize.PX_24 }),
        subjectAs: 'h6',
        renderPostTimeBeforeAuthor: true,
        ...commonProps,
        ...inlineProps,
        useVideoPreview: false
      }
    };

    return listLayoutTypeAndProps;
  } else {
    const {
      layoutOptions: {
        useAuthorRank,
        useBody,
        useRepliesCount,
        usePreviewMedia,
        useTags,
        useBoardLink,
        useViewCount,
        useKudosCount,
        useTimeToRead,
        useSolvedBadge
      }
    } = layoutProps;
    // props used by both the DetailedList and Card layouts
    const inlineAndCardSharedProps: MessageViewCommonProps = {
      useAuthorRank,
      useTags,
      useNode: useBoardLink,
      useNodeHoverCard: useBoardLink,
      useNodeLink: useBoardLink,
      useBody: useBody,
      useTextBody: useBody,
      usePreviewMedia,
      useViewCount,
      useKudosCount,
      useRepliesCount,
      useTimeToRead,
      useSolvedBadge
    };

    if (isLayoutPropsDetailedListLayout(layoutProps)) {
      const { useUnreadMessages } = layoutProps.layoutOptions;
      const detailedListLayoutTypeAndProps: ItemViewTypeAndProps<
        ItemType.MESSAGE,
        MessageViewVariant.INLINE
      > = {
        type: MessageViewVariant.INLINE,
        props: {
          ...commonProps,
          ...inlineProps,
          ...inlineAndCardSharedProps,
          clampBodyLines: 3,
          useUnreadCount: useUnreadMessages
        }
      };

      return detailedListLayoutTypeAndProps;
    } else {
      const { useCenteredCardContent } = layoutProps.layoutOptions;
      const cardLayoutTypeAndProps: ItemViewTypeAndProps<
        ItemType.MESSAGE,
        MessageViewVariant.CARD
      > = {
        type: MessageViewVariant.CARD,
        props: {
          // override MessageViewCard default props applied by MessageView.tsx
          portraitClampBodyLines: 3,
          landscapeClampBodyLines: 3,
          useClickableCard: true,
          ...commonProps,
          ...inlineAndCardSharedProps,
          useCenteredContent: useCenteredCardContent
        }
      };

      return cardLayoutTypeAndProps;
    }
  }
}

/**
 * Determines which Panel should be used by the PaneledItemList
 *
 * @param hasMessages whether the component has any messages
 * @param layoutProps the FeaturedContentWidget message Layout props
 */
function getPanelType(hasMessages: boolean, layoutProps: LayoutProps): PanelType {
  if (!hasMessages) {
    return PanelType.NONE;
  } else if (isLayoutPropsCardLayout(layoutProps)) {
    return PanelType.SPACED;
  } else {
    const ListStyleToPanelTypeMap: Record<FeaturedContentWidgetListStyle, PanelType> = {
      [FeaturedContentWidgetListStyle.SPACE]: PanelType.STANDARD,
      [FeaturedContentWidgetListStyle.DIVIDE]: PanelType.DIVIDER,
      [FeaturedContentWidgetListStyle.BORDER]: PanelType.BUBBLE
    };

    return ListStyleToPanelTypeMap[layoutProps.listStyle];
  }
}

/**
 * Renders the list of featured content messages
 *
 * @author Jonathan Bridges
 */
const FeaturedContentMessageList: React.FC<React.PropsWithChildren<Props>> = ({
  contentQueryVariables,
  onContentQueryUpdate,
  toggleModal,
  header,
  layoutProps,
  showPager,
  className
}) => {
  const cx = useClassNameMapper();
  const i18n = useTranslation(EndUserComponent.FEATURED_CONTENT_MESSAGE_LIST);
  const { formatMessage, loading: textLoading } = i18n;

  const { first: pageSize } = contentQueryVariables;

  const contentQueryResult: QueryResult<
    FeaturedContentWidgetQuery,
    FeaturedContentWidgetQueryVariables
  > = useFeaturedContentWidget(contentQueryVariables);

  useEffect(() => {
    onContentQueryUpdate(contentQueryResult);
  }, [contentQueryResult, onContentQueryUpdate]);

  if (textLoading) {
    return null;
  }

  /**
   * Renders the empty state for the FeaturedContentWidget
   */
  const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => (
    <FeaturedWidgetPlaceholder buttonText={formatMessage('edit')} onClick={toggleModal} />
  );

  const containsMessages: boolean =
    contentQueryResult?.data?.featuredContentWidget?.messages?.edges?.length > 0;

  const { title, titleSrOnly } = header;
  const cardLayoutListVariant: GridListTypeAndProps<MessageViewFragment> = {
    type: ListVariant.GRID,
    props: {
      itemSpacing: ListItemSpacing.LG,
      colProps: getGridListColumns(contentQueryVariables.first)
    }
  };

  return (
    <PaneledItemList<
      MessageViewFragment,
      ItemType.MESSAGE,
      ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
      FeaturedContentWidgetQuery,
      FeaturedContentWidgetQueryVariables
    >
      queryResult={contentQueryResult}
      itemPath="featuredContentWidget.messages"
      onUpdate={(newConnection: MessageConnection): FeaturedContentWidgetQuery => {
        return {
          featuredContentWidget: {
            ...contentQueryResult.data.featuredContentWidget,
            messages: newConnection
          }
        } as FeaturedContentWidgetQuery;
      }}
      pageSize={pageSize}
      pagerVariant={{ type: PagerVariant.LOAD_MORE }}
      type={ItemType.MESSAGE}
      variant={getItemViewTypeAndProps(layoutProps)}
      useEmpty
      empty={EmptyState}
      panel={getPanelType(containsMessages, layoutProps)}
      listVariant={isLayoutPropsCardLayout(layoutProps) ? cardLayoutListVariant : undefined}
      header={containsMessages && title}
      isHeaderSrOnly={titleSrOnly}
      useFooter={showPager}
      footerClassName={cx({ 'bg-transparent mt-15': isLayoutPropsCardLayout(layoutProps) })}
      className={cx(className)}
    />
  );
};
export default FeaturedContentMessageList;
