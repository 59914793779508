import type { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import type { QueryResult } from '@apollo/client/react';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import FeaturedWidgetContext from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { useContext } from 'react';
import type {
  FeaturedContentWidgetQuery,
  FeaturedContentWidgetQueryVariables,
  ProvisionedFeaturedContentMessagesQuery,
  ProvisionedFeaturedContentMessagesQueryVariables
} from '../../../../types/graphql-types';
import contentQuery from './FeaturedContentWidget.query.graphql';
import provisionalMessagesQuery from './ProvisionedFeaturedContentMessages.query.graphql';

/**
 * Helper for retrieving the messages associated with a FeaturedContentWidget instance
 *
 * If the instance has been added during the current page editor session queries the messages endpoint to display any
 * selected messages, otherwise, queries the featuredContentWidget endpoint directly
 *
 * @param queryVariables variables used by the FeaturedWidgetContent query
 * @param skip whether to skip the featured content widget and provisional messages queries
 * @param fetchPolicy the fetch policy for the query
 * @param onCompleted callback function invoked when
 */
export default function useFeaturedContentWidget(
  queryVariables: FeaturedContentWidgetQueryVariables,
  skip = false,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  onCompleted?: (data: FeaturedContentWidgetQuery | ProvisionedFeaturedContentMessagesQuery) => void
): QueryResult<FeaturedContentWidgetQuery, FeaturedContentWidgetQueryVariables> {
  const {
    publicConfig: { auroraFeaturedWidgetsEnabled }
  } = useContext(TenantContext);
  const { featuredWidgetInstances } = useContext(FeaturedWidgetContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instanceId, quiltId, coreNodeId, ...restQueryVariables } = queryVariables;

  const sessionInstance: FeaturedWidgetInstance = featuredWidgetInstances?.find(
    featuredWidgetInstance => featuredWidgetInstance.instanceId === instanceId
  );
  const sessionInstanceHasMessages: boolean = sessionInstance?.featuredItemIds?.length > 0;
  const skipQuery: boolean = skip || !auroraFeaturedWidgetsEnabled || !!sessionInstance;
  const skipProvisionalQuery: boolean =
    skip ||
    !auroraFeaturedWidgetsEnabled ||
    !sessionInstance ||
    // API throws an error when using the message id constraint and providing an empty array of ids, so skip
    (sessionInstance && !sessionInstanceHasMessages);

  // retrieves the messages and last modified information if the widget is already stored
  const featuredContentWidgetQueryResult = useQueryWithTracing<
    FeaturedContentWidgetQuery,
    FeaturedContentWidgetQueryVariables
  >(module, contentQuery, {
    variables: queryVariables,
    skip: skipQuery,
    fetchPolicy,
    onCompleted
  });

  // if the widget has been added during the current page editor session queries for the selected messages
  //  directly
  const provisionedMessagesQueryResult = useQueryWithTracing<
    ProvisionedFeaturedContentMessagesQuery,
    ProvisionedFeaturedContentMessagesQueryVariables
  >(module, provisionalMessagesQuery, {
    variables: {
      ...restQueryVariables,
      messageIds: sessionInstance?.featuredItemIds
    },
    skip: skipProvisionalQuery,
    fetchPolicy,
    onCompleted
  });

  if (!sessionInstance) {
    return featuredContentWidgetQueryResult;
  } else {
    const { loading, error, data } = provisionedMessagesQueryResult;
    // fallbacks provided in the event the widget was added during the current session but the query is skipped due
    //  to no messages yet being associated
    return {
      ...featuredContentWidgetQueryResult,
      loading: loading || false,
      error: error || null,
      data: {
        featuredContentWidget: {
          messages: data?.messages || { totalCount: 0, edges: [], pageInfo: undefined }
        }
      }
    };
  }
}
